import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'BarbaDev', // e.g: 'Name | Developer'
  lang: 'pt', // e.g: en, es, fr, jp
  description: 'Software Studio. Desenvolvedores de Software. Tenha sua presença on-line.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Bem vindo ao',
  name: 'BarbaDev',
  subtitle: 'Programamos a sua visão.',
  cta: 'Saiba Mais',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.png',
  paragraphOne: 'Em um mundo cada vez mais digital, é importante marcar sua presença on-line.',
  paragraphTwo: 'Usando tecnologia de ponta, a BarbaDev entrega software de qualidade para tornar a sua visão em realidade.',
  paragraphThree: 'Seja para criar um aplicativo, uma loja on-line ou uma página institucional, pode contar a BarbaDev!',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'mobile.jpg',
    title: 'Aplicativos Mobile e Web',
    info: 'Usamos a tecnologia mais atual para entregar aplicativos para as mais variadas plataformas.',
    info2: 'Tenha seu aplicativo na Web, em celulares e tablets Android, iPhones e iPads!',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'sites2.jpg',
    title: 'Páginas Institucionais',
    info: 'Conte com a BarbaDev para ter a sua empresa na Internet.',
    info2: 'Divulgue sua empresa, sua marca e seus produtos na Internet, com sites modernos que podem ser acessados tanto do computador quanto do celular.',
    url: '',
    repo: '', // if no repo, the button will not show up
  },  
  {
    id: nanoid(),
    img: 'ecommerce.jpg',
    title: 'Lojas Virtuais',
    info: 'Venda para todo o Brasil com uma loja virtual.',
    info2: 'Adicione uma loja ao seu site ou crie um site novo com uma loja.',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'maintenance.jpg',
    title: 'Desenvolvimento e Manutenção de Sistemas',
    info: 'Tem uma necessidade de sofware específica?',
    info2: 'Desenvolvemos e fazemos manutenção de sistemas internos, focados no seu negócio. Fale com a gente!',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Pronto para discutir seu projeto? Suas ideias?',
  btn: 'Vamos conversar!',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: '',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: '',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: '',
    },
    {
      id: nanoid(),
      name: 'github',
      url: '',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
